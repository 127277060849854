import React from 'react';
import PropTypes from 'prop-types';

import { Info, Emoji } from './styles';
import { Form } from '@theme/form';
import { Button } from '@formComponents';

export const CreateWalletStep4 = ({ onSubmit }) => (
  <Form>
    <Info bold>
      <Emoji role="img" aria-label="clap">
        <span role="img" aria-label="clap">
          👏
        </span>
      </Emoji>
      Congratulations!
    </Info>
    <Info>You have successfully created your EEX wallet!</Info>
    <Button testId="create-wallet-open-btn" onClick={onSubmit}>
      Open Wallet
    </Button>
  </Form>
);

CreateWalletStep4.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
