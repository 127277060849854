import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';
import { ReactComponent as Eye } from '@images/eye.svg';
import { colors } from '@theme';

export const Input = ({
  value,
  onChange,
  name,
  type,
  testId,
  errorTestId,
  placeholder,
  maxLength,
  isDisabled,
  label,
  error,
  withEye,
  customStyles
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const toggleVisibility = () => setIsHidden(!isHidden);

  return (
    <Styled.Label>
      {label}
      <Styled.Input
        data-test={testId}
        value={value}
        onChange={onChange}
        name={name}
        type={type === 'password' ? (isHidden ? 'password' : 'text') : type}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={isDisabled}
        error={error}
        {...customStyles}
      />
      {withEye && (
        <Styled.Icon onClick={toggleVisibility}>
          <Eye width={18} color={isHidden ? colors.gray : colors.violet} />
        </Styled.Icon>
      )}
      {error && <Styled.Error data-test={errorTestId}>{error}</Styled.Error>}
    </Styled.Label>
  );
};

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  errorTestId: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  withEye: PropTypes.bool,
  customStyles: PropTypes.object
};
