import styled from 'styled-components';
import { breakpoints } from '@theme';

export const ContentWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  padding: 10px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 40px;
  }
`;
