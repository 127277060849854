import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';
import { INITIAL_CENTER_PAGE } from '@constants';
import { ReactComponent as Prev } from '@images/prev.svg';
import { ReactComponent as Next } from '@images/next.svg';
import { colors } from '@theme/index';

export const Pagination = ({ offset, limit, total, onChange }) => {
  const currentPage = Math.floor(offset / limit);
  const totalPages = Math.ceil(total / limit);

  const handleChange = useCallback(
    page =>
      onChange({
        offset: page * limit,
        limit
      }),
    [onChange, limit]
  );

  const pages = useMemo(
    () =>
      Array(totalPages)
        .fill(0)
        .map((_, i) => i),
    [totalPages]
  );

  const firstPage = pages[0];
  const lastPage = pages[pages.length - 1];

  const visiblePages =
    currentPage < INITIAL_CENTER_PAGE ? pages.slice(0, 5) : pages.slice(currentPage - 2, currentPage + 3);

  const pagesJSX = useMemo(
    () =>
      visiblePages.map((page, index) => {
        const isActive = currentPage === page;
        const pageLabel = page + 1;
        return (
          <Styled.PaginationItem key={`${page}-${index}`}>
            <Styled.PaginationLink active={isActive} onClick={() => handleChange(page)} data-test="pagination-number">
              {pageLabel}
            </Styled.PaginationLink>
          </Styled.PaginationItem>
        );
      }),
    [currentPage, handleChange, visiblePages]
  );

  const prev = () => {
    handleChange(currentPage - 1);
  };

  const doublePrev = () => {
    handleChange(currentPage - 5);
  };

  const next = () => {
    handleChange(currentPage + 1);
  };

  const doubleNext = () => {
    handleChange(currentPage + 5);
  };

  return (
    <Styled.Pagination>
      <Styled.PaginationItem>
        <Styled.PaginationLink
          disabled={currentPage < firstPage + 5}
          onClick={doublePrev}
          data-test="pagination-double-prev"
        >
          <Styled.Icon disabled={currentPage < firstPage + 5}>«</Styled.Icon>
        </Styled.PaginationLink>
      </Styled.PaginationItem>
      <Styled.PaginationItem>
        <Styled.PaginationLink onClick={prev} data-test="pagination-prev">
          <Prev color={currentPage === firstPage ? colors.lightGray : colors.violet} />
        </Styled.PaginationLink>
      </Styled.PaginationItem>
      {pagesJSX}
      <Styled.PaginationItem>
        <Styled.PaginationLink disabled={currentPage === lastPage} data-test="pagination-next" onClick={next}>
          <Next color={currentPage === lastPage ? colors.lightGray : colors.violet} />
        </Styled.PaginationLink>
      </Styled.PaginationItem>
      <Styled.PaginationItem>
        <Styled.PaginationLink
          onClick={doubleNext}
          disabled={currentPage > lastPage - 5}
          data-test="pagination-double-next"
        >
          <Styled.Icon disabled={currentPage > lastPage - 5}>»</Styled.Icon>
        </Styled.PaginationLink>
      </Styled.PaginationItem>
    </Styled.Pagination>
  );
};

Pagination.propTypes = {
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
