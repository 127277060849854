import React from 'react';
import PropTypes from 'prop-types';

import { Info } from './styles';
import { Button } from '@formComponents';
import { Form } from '@theme/form';

export const CreateWalletStep2 = ({ onSubmit }) => {
  return (
    <Form>
      <Info>
        You will now proceed creating your secret phrase. This makes it easy to back up and restore your account.
      </Info>
      <Info>
        <strong>Never disclose your backup phrase.</strong> Memorize this phrase or write it on the piece of paper.
      </Info>
      <Button onClick={onSubmit} testId="create-wallet-step2-next">
        Generate seed phrase
      </Button>
    </Form>
  );
};

CreateWalletStep2.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
