import styled from 'styled-components';
import { breakpoints } from '@theme';

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  ${({ justify }) => justify && `justify-content: ${justify}`}
  ${({ align }) => align && `align-items: ${align}`}
  ${({ direction }) => direction && `flex-direction: ${direction}`}
  ${({ padding }) => padding && `padding: ${padding}px`}
  ${({ margin }) => margin && `margin: ${margin}`}
  ${({ background }) => background && `background: ${background}`}
  ${({ width }) => width && `width: ${width}`}
  ${({ border }) => border && `border-bottom: 1px solid #E4E3E8`}
  ${({ borderTop }) => borderTop && `border-top: 1px solid #E4E3E8`}

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    margin: 0;
    ${({ padding }) => padding && `padding: ${padding / 2}px`}
  }
`;
