import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from '@theme/form';

export const Form = ({ children, onSubmit }) => {
  const handleSubmit = event => {
    event.preventDefault();
    onSubmit();
  };

  return <Styled.Form onSubmit={handleSubmit}>{children}</Styled.Form>;
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired
};
