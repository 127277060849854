import styled from 'styled-components';
import { breakpoints, colors } from '@theme';

export const Text = styled.span`
  color: ${colors.almostBlack};
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ color }) => color && `color: ${color};`}
  ${({ weight }) => weight && `font-weight: ${weight};`}
  ${({ size }) => size && `font-size: ${size}px;`}

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 12px;
  }
`;

export const CopyIcon = styled.div`
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    & > * {
      color: ${colors.violet};
    }
  }
`;
