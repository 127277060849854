import styled from 'styled-components';
import { colors, breakpoints } from '@theme';

export const Label = styled.label`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  width: 100%;
  margin: 10px 0 15px;
  border-radius: 4px;
  border: 1px solid ${colors.lightGray};
  height: 40px;
  padding-left: 8px;
  font-size: 16px;

  ${({ error }) => error && `border: 2px solid ${colors.red};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ background }) => background && `background: ${background};`}
`;

export const Error = styled.div`
  width: 100%;
  font-weight: 500;
  color: ${colors.red};
  margin-bottom: 10px;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 44px;
  right: 10px;
  cursor: pointer;
`;
