import styled from 'styled-components';

export const Message = styled.div`
  background: #f7f7f8;
  border-radius: 4px;
  border: 1px solid #e4e3e8;
  font-size: 18px;
  padding: 20px;

  position: relative;
`;

export const Small = styled.span`
  color: #a8a5b4;
  font-size: 12px;
  display: block;
  text-align: center;
  margin: 10px 0 30px;
  cursor: pointer;
`;

export const Hidden = styled.input`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
`;
