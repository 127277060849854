import BigNumber from 'bignumber.js';

export const modifyAmount = amount => {
  const dotIndex = amount.indexOf('.');
  return dotIndex === -1 ? amount : amount.slice(0, dotIndex + 9);
};

export const convertExponentialToDecimal = expNumber => {
  return new BigNumber(expNumber).toFixed(18);
};
