import { handleActions } from 'redux-actions';
import { setLwAction, setCreateWalletStep } from '@features/Auth/actions';

const initialState = {
  lwService: null,
  createWalletStep: 1
};

export const auth = handleActions(
  {
    [setLwAction]: (state, { payload: { lw } }) => ({
      ...state,
      lwService: { ...state.lwService, ...lw }
    }),
    [setCreateWalletStep]: (state, { payload: { step } }) => ({
      ...state,
      createWalletStep: step
    })
  },
  initialState
);
