export const INITIAL_CENTER_PAGE = 3;

export const errors = {
  insufficient: 'Insufficient funds.',
  invalidAddress: 'Public address is not valid',
  required: 'This field is required'
};

export const statuses = {
  none: 'none',
  loading: 'loading',
  success: 'success',
  error: 'error'
};
