import { passwordRules } from '@features/Auth/validation';

export const validation = [
  {
    rules: passwordRules,
    name: 'password'
  },
  {
    matchWithField: {
      field: 'password',
      message: 'Password and Confirm password fields should be equal.'
    },
    name: 'confirm'
  }
];
