import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getAddressToMakeTx, getAmount } from '@features/Cabinet/selectors';
import { H2, Info, Text, buttons } from './styles';
import { modifyAmount } from '@helpers';
import { ReactComponent as Cross } from '@images/cross.svg';
import { Button } from '@formComponents';
import { Flex } from '@theme';

export const SendTxStep3 = ({ onClose }) => {
  const addressToMakeTx = useSelector(getAddressToMakeTx);
  const tokensAmount = useSelector(getAmount);

  return (
    <>
      <Flex border>
        <H2>Transaction was successfully sent!</H2>
        <Cross onClick={onClose} id="close" data-test="step3-cross-btn" />
      </Flex>
      <Flex direction="column" padding="40">
        <Flex direction="column" align="flex-start" padding="0">
          <Text padding="0" size="16px">
            To:{' '}
          </Text>
          <Text padding="0" bold size="16px" data-test="step3-recipient">
            {addressToMakeTx}
          </Text>
          <Text padding="0" size="16px">
            Amount:{' '}
          </Text>
          <Info margin="0" bold size="38px" data-test="step3-amount">
            {modifyAmount(tokensAmount)} EEX Coins
          </Info>
        </Flex>
      </Flex>

      <Flex borderTop justify="flex-end">
        <Button onClick={onClose} customStyles={buttons.ok} testId="ok-btn">
          OK
        </Button>
      </Flex>
    </>
  );
};

SendTxStep3.propTypes = {
  onClose: PropTypes.func.isRequired
};
