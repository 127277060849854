import BigNumber from 'bignumber.js';
import { paidBy } from '../constants';

export const countIfPaidBy = (senderOrRecipient, amount, fixedFee, percentageFee) => {
  if (!amount) return null;

  const percent = new BigNumber(1).minus(new BigNumber(percentageFee));

  const youWillSend =
    senderOrRecipient === paidBy.sender
      ? new BigNumber(amount).plus(new BigNumber(fixedFee).times(percent)).dividedBy(percent)
      : new BigNumber(amount);

  const recipientWillReceive =
    senderOrRecipient === paidBy.sender
      ? new BigNumber(amount)
      : new BigNumber(amount).minus(new BigNumber(fixedFee)).times(percent);

  const totalFee =
    senderOrRecipient === paidBy.sender
      ? youWillSend.minus(recipientWillReceive)
      : new BigNumber(amount).minus(recipientWillReceive);

  return {
    youWillSend,
    recipientWillReceive,
    totalFee
  };
};
