import styled from 'styled-components';
import { colors, breakpoints } from '@theme';

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  color: ${colors.white};
  background: ${colors.violet};
  border: 1px solid ${colors.violet};
  outline: none;

  ${({ background }) => background && `background: ${background};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ border }) => border && `border: 1px solid ${border};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ customStyles }) => customStyles && customStyles}

  @media (max-width: ${breakpoints.sm}px) {
    ${({ hiddenForMobileDevices }) => hiddenForMobileDevices && `display: none;`}
  }
`;
