import React from 'react';
import { getAddressName } from '../helpers';
import { Link } from '../styles';

export const getAddresses = ({ sender, recipient, hash }) => [
  {
    label: 'From: ',
    value: sender,
    jsx: getAddressName(sender),
    testId: 'sender-copy-icon-'
  },
  {
    label: 'To: ',
    value: recipient,
    jsx: getAddressName(recipient),
    testId: 'recipient-copy-icon-'
  },
  {
    label: 'Hash: ',
    value: hash,
    jsx: (
      <Link target="_blank" href={`${process.env.REACT_APP_EXCHANGE_URL}/transaction/${hash}`}>
        {hash}
      </Link>
    ),
    testId: 'hash-copy-icon-'
  }
];
