import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Pagination, CopyText } from '@common';
import { colors } from '@theme/colors';
import * as Styled from './styles';
import { getTransactionsAsync, getAllTransactionsAsync } from '@features/Cabinet/actions';
import {
  getAllTransactions,
  getTransactions,
  getPublicAddress,
  getAllTransactionsErrorSelector,
  getTransactionsErrorSelector
} from '@features/Cabinet/selectors';
import { modifyAmount } from '@helpers';
import { getAddresses } from './data';
import { usePagination } from '@hooks';
import { ReactComponent as Withdrawal } from '@images/withdrawal.svg';
import { ReactComponent as Replenishment } from '@images/replenishment.svg';

export const Transactions = () => {
  const dispatch = useDispatch();
  const allTransactionsList = useSelector(getAllTransactions);
  const transactionsList = useSelector(getTransactions);
  const address = useSelector(getPublicAddress);
  const getAllTransactionsError = useSelector(getAllTransactionsErrorSelector);
  const getTransactionsError = useSelector(getTransactionsErrorSelector);
  const [currentTransactions, setCurrentTransactions] = useState([]);
  const [errors, setErrors] = useState([]);

  const getTxs = useMemo(
    () => (offset, limit) => {
      return address && dispatch(getTransactionsAsync({ offset, limit, address }));
    },
    [address]
  );

  const { offset, limit, handleChange } = usePagination(0, 5, getTxs);

  useEffect(() => {
    if (address) {
      dispatch(getAllTransactionsAsync({ address }));
      dispatch(getTransactionsAsync({ address, limit, offset }));
    }
  }, [address, limit, offset]);

  useEffect(() => {
    setCurrentTransactions(transactionsList);
  }, [transactionsList]);

  useEffect(() => {
    getAllTransactionsError && setErrors([...errors, getAllTransactionsError]);
  }, [getAllTransactionsError, errors]);

  useEffect(() => {
    getTransactionsError && setErrors([...errors, getTransactionsError]);
  }, [getTransactionsError, errors]);

  return (
    <>
      <Styled.Container>
        {Boolean(currentTransactions.length) ? (
          currentTransactions.map((transaction, index) => {
            const date = new Date(transaction.timestamp).toLocaleString();

            return (
              <Styled.Row data-test={`transactions-row-${index}`} key={transaction.block_number}>
                <Styled.Flex>
                  <Styled.Icon>{transaction.recipient === address ? <Replenishment /> : <Withdrawal />}</Styled.Icon>

                  <Styled.Column>
                    <Styled.Text size={18} weight="bold">
                      {transaction.recipient === address ? 'Deposit replenishment' : 'Deposit withdrawal'}
                    </Styled.Text>
                    <Styled.Text color={colors.gray} weight="bold">
                      {date}
                    </Styled.Text>
                  </Styled.Column>
                </Styled.Flex>

                <Styled.TxWrapper>
                  <Styled.Amount>
                    <Styled.Text size={16}>{modifyAmount(transaction.amount)} </Styled.Text>
                    <Styled.Text color={colors.gray}>Fee: {modifyAmount(transaction.fee)}</Styled.Text>
                  </Styled.Amount>
                  {getAddresses(transaction).map(({ label, value, jsx, testId }, index) => (
                    <Styled.Wrapper key={index}>
                      <Styled.Text color={colors.gray}>{label}</Styled.Text>
                      <CopyText text={value} testId={`${testId}${index}`}>
                        {jsx}
                      </CopyText>
                    </Styled.Wrapper>
                  ))}
                </Styled.TxWrapper>
              </Styled.Row>
            );
          })
        ) : errors.length ? (
          errors.map((error, index) => (
            <Styled.Error data-test="transactions-error" key={index}>
              {error}
            </Styled.Error>
          ))
        ) : (
          <Styled.Info data-test="transactions-empty">The transaction list is empty</Styled.Info>
        )}
      </Styled.Container>

      <Pagination offset={offset} limit={limit} onChange={handleChange} total={allTransactionsList.length} />
    </>
  );
};
