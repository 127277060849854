import styled from 'styled-components';
import { breakpoints, colors } from '@theme';

export const Root = styled.section`
  display: grid;
  grid-gap: 30px;

  @media (min-width: ${breakpoints.mdToLg}px) {
    grid-template-columns: 1fr 2.5fr;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

export const Header = styled.header`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    to left,
    ${colors.theDarkestViolet},
    ${colors.darkViolet},
    ${colors.indigo},
    ${colors.pinkViolet}
  );
  color: #fff;
  padding: 0 40px;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 0 10px;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.md}px) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 18px;
  height: 100px;
  line-height: 100px;
  cursor: pointer;
  margin-left: 30px;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  @media (max-width: ${breakpoints.md}px) {
    font-size: 16px;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e3e8;
  border-radius: 4px;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: row;
  }

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const QRWrapper = styled.div`
  background: #f7f7f8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #e4e3e8;
  padding: 80px;

  @media (max-width: ${breakpoints.md}px) {
    width: 50%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Small = styled.span`
  color: #a8a5b4;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;

  ${({ noCursor }) => noCursor && `cursor: default;`}
  ${({ active }) => active && `color: green;`}
`;

export const Hidden = styled.input`
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
`;

export const CopyArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  background: ${colors.lightGray};
  border-radius: 4px;
  color: ${colors.almostBlack};
  text-align: center;
`;

export const Text = styled.p`
  font-family: 'Roboto', sans-serif;
  color: #12072c;
  display: inline;
  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ color }) => color && `color: ${color}`}
  margin: 0 5px 0 0;

  @media (max-width: ${breakpoints.sm}px) {
    margin: 0 5px 0 0;
    font-size: 14px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Left = styled.div`
  display: grid;
  grid-template-rows: 60px 1fr;
  grid-gap: 20px;
`;

export const Right = styled.div`
  display: grid;
  grid-template-rows: 60px 1fr 40px;
  grid-gap: 20px;

  @media (max-width: ${breakpoints.sm}px) {
    grid-template-rows: 1fr;
  }
`;

export const LogoImg = styled.img`
  width: 64px;
  margin-right: 40px;

  @media (max-width: ${breakpoints.md}px) {
    margin-right: 20px;
    width: 54px;
  }
`;

export const LogOutIcon = styled.img`
  width: 30px;
  cursor: pointer;
`;

export const Icon = styled.div`
  cursor: pointer;
`;

export const buttons = {
  send: `margin: 0 0 0 20px`
};
