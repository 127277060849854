import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CreateWalletStep1, CreateWalletStep2, CreateWalletStep3, CreateWalletStep4 } from './components';
import { setCreateWalletStep } from '@features/Auth/actions';
import { getLw } from '@features/Auth/selectors';

export const CreateWallet = () => {
  const dispatch = useDispatch();
  const lw = useSelector(getLw);
  const [step, setStep] = useState(1);
  const [seedPhrase, setSeedPhrase] = useState('');
  const [password, setPassword] = useState('');

  const nextStep = () => {
    setStep(step + 1);
    dispatch(setCreateWalletStep(step + 1));
  };

  const SignIn = event => {
    event.preventDefault();
    lw.logInToWallet();
    dispatch(setCreateWalletStep(1));
  };

  const onFirstStepSubmit = password => {
    setPassword(password);
    nextStep();
  };

  const onSecondStepSubmit = () => {
    lw.createWallet(password);
    setSeedPhrase(lw.getSeed());
    nextStep();
  };

  return (
    <div>
      {step === 1 && <CreateWalletStep1 onSubmit={onFirstStepSubmit} />}
      {step === 2 && <CreateWalletStep2 onSubmit={onSecondStepSubmit} />}
      {step === 3 && <CreateWalletStep3 seedPhrase={seedPhrase} onSubmit={nextStep} />}
      {step === 4 && <CreateWalletStep4 onSubmit={SignIn} />}
    </div>
  );
};
