import styled from 'styled-components';
import { breakpoints, colors } from '@theme';

export const H2 = styled.h2`
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin: 0;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 22px;
  }
`;

export const Info = styled.span`
  margin-left: 5px;
  color: #441eda;

  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ size }) => size && `font-size: ${size};`}
  ${({ margin }) => margin && `margin: ${margin};`}

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 24px;
    margin-left: 0;
  }
`;

export const Message = styled.span`
  margin-left: 5px;
  color: #441eda;
  ${({ size }) => size && `font-size: ${size};`}
`;

export const Error = styled.div`
  color: rgb(237, 34, 34);
  margin-top: 10px;
  width: 100%;
`;

export const Text = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding: 0;

  ${({ size }) => size && `font-size: ${size};`}
  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ padding }) => padding && `padding: ${padding};`}

  @media (max-width: ${breakpoints.sm}px) {
    word-wrap: break-word;
    ${({ width }) => width && `width: ${width};`}
  }
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  margin: 10px 0 0px 0;

  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  align-items: center;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const buttons = {
  cancel: `margin-left:10px; width: 150px; color: ${colors.violet}; background: ${colors.white};`
};

export const confirmBtn = isLoading =>
  `width: 150px; color: ${colors.white}; background: ${isLoading ? colors.lightViolet : colors.violet}; border: ${
    isLoading ? colors.lightViolet : colors.violet
  }`;
