import styled from 'styled-components';
import { breakpoints, colors } from '@theme';

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  align-items: center;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const Amounts = styled.div`
  width: 100%;
  min-height: 80px;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 10px;
  justify-content: center;

  @media (max-width: ${breakpoints.md}px) {
    grid-template-columns: 1fr;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.md}px) {
    grid-template-columns: 1fr;
  }
`;

export const H2 = styled.h2`
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin: 0;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 22px;
  }
`;

export const Info = styled.span`
  margin-left: 5px;
  color: #441eda;

  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ size }) => size && `font-size: ${size};`}

  ${({ margin }) => margin && `margin: ${margin};`}

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 24px;
    margin-left: 0;
  }
`;

export const Message = styled.span`
  margin-left: 5px;
  color: #441eda;
  ${({ size }) => size && `font-size: ${size};`}
`;

export const Error = styled.div`
  color: rgb(237, 34, 34);
  margin-top: 10px;
  width: 100%;
`;

export const Text = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding: 0;

  ${({ size }) => size && `font-size: ${size};`}
  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ padding }) => padding && `padding: ${padding};`}

  @media (max-width: ${breakpoints.sm}px) {
    word-wrap: break-word;
    ${({ width }) => width && `width: ${width};`}
  }
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  margin: 10px 0 0px 0;

  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}


  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 38px 0 10px;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 4px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #441eda;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const CameraWrapper = styled.section`
  display: grid;
  grid-template-columns: auto 12px;
  margin: 10px 0;
  padding: 0;
`;

export const checkboxStyles = {
  checked: {
    background: '#441eda',
    ':after': {
      content: '',
      display: 'block',
      borderRadius: '50%',
      width: '18px',
      height: '18px',
      marginLeft: '21px',
      transition: '0.2s'
    }
  },
  unchecked: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '42px',
    height: '26px',
    borderRadius: '15px',
    background: '#bebebe',
    cursor: 'pointer',
    ':after': {
      content: '',
      display: 'block',
      borderRadius: '50%',
      width: '18px',
      height: '18px',
      margin: '4px',
      background: '#ffffff',
      boxShadow: '1px 3px 3px 1px rgba(0, 0, 0, 0.2)',
      transition: '0.2s'
    }
  }
};

export const cameraStyles = {
  height: 240,
  width: '100%',
  padding: '20px'
};

export const nextBtn = disabled =>
  `width: 150px; color: ${colors.white}; border: ${disabled ? colors.lightViolet : colors.violet}; background: ${
    disabled ? colors.lightViolet : colors.violet
  }`;

export const buttons = {
  cancel: `width:150px; margin-left: 10px; color: ${colors.violet}; background: ${colors.white}`,
  scan: `color: ${colors.violet}; background: ${colors.white};`
};
