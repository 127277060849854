import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const Form = styled.form`
  min-height: 22vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
  position: relative;
`;

export const Error = styled.div`
  color: #ed2222;
  font-weight: 500;
  margin-bottom: 10px;
`;
