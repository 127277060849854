import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import { watchGetAllTransactionsSaga, watchGetTransactionsSaga } from '@features/Cabinet/sagas';
import { auth } from '@features/Auth/reducers';
import { cabinet } from '@features/Cabinet/reducers';

function* rootSaga() {
  yield all([fork(watchGetTransactionsSaga), fork(watchGetAllTransactionsSaga)]);
}
const rootReducer = combineReducers({
  cabinet,
  auth
});

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);
