import { call, takeLatest, put } from 'redux-saga/effects';
import {
  getTransactionsAsync,
  getAllTransactionsAsync,
  setTransactionsStatus,
  setTransactionsData,
  setTransactionsError,
  setAllTransactionsStatus,
  setAllTransactionsData,
  setAllTransactionsError
} from '@features/Cabinet/actions';
import { statuses } from '@constants';

function* getAllTransactionsSaga({ payload }) {
  if (payload) {
    yield put(setAllTransactionsStatus(statuses.loading));
    let { address } = payload;

    try {
      const data = yield call(() => {
        return fetch(`${process.env.REACT_APP_TRANSACTIONS_URL}/api/v1/explorer/wallet/${address}`, {
          method: 'GET'
        }).then(response => response.json());
      });

      if (data) {
        yield put(setAllTransactionsStatus(statuses.success));
        yield put(setAllTransactionsData(data.transactions));
      }
    } catch (error) {
      yield put(setAllTransactionsStatus(statuses.error));
      yield put(setAllTransactionsError('Request for all transactions failed. Please refresh the page.'));
    }
  }
}

function* getTransactionsSaga({ payload }) {
  if (payload) {
    yield put(setTransactionsStatus(statuses.loading));

    let { limit, offset, address } = payload;
    try {
      const data = yield call(() => {
        return fetch(
          `${process.env.REACT_APP_TRANSACTIONS_URL}/api/v1/explorer/wallet/${address}?offset=${offset}&limit=${limit}`,
          {
            method: 'GET'
          }
        ).then(response => response.json());
      });

      if (data) {
        yield put(setTransactionsStatus(statuses.success));
        yield put(setTransactionsData(data.transactions));
      }
    } catch (error) {
      yield put(setTransactionsStatus(statuses.error));
      yield put(setTransactionsError('Request for current page transactions failed. Please refresh the page.'));
    }
  }
}

export function* watchGetTransactionsSaga() {
  yield takeLatest(getTransactionsAsync().type, getTransactionsSaga);
}

export function* watchGetAllTransactionsSaga() {
  yield takeLatest(getAllTransactionsAsync().type, getAllTransactionsSaga);
}
