import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './style';

export const Tabs = ({ tabs, children, renderHeader }) => {
  const [active, setActive] = useState(0);
  const header = useMemo(
    () => (
      <Styled.Header>
        {renderHeader
          ? renderHeader(active, setActive)
          : tabs.map((tab, id) => (
              <Styled.Tab
                data-test={tab.testId}
                key={id}
                active={id === active}
                disabled={tab.isDisabled}
                onClick={() => setActive(id)}
              >
                {tab.label}
              </Styled.Tab>
            ))}
      </Styled.Header>
    ),
    [tabs, active, renderHeader]
  );

  return (
    <Styled.Root>
      {header}
      {children[active]}
    </Styled.Root>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.array.isRequired,
  renderHeader: PropTypes.func
};

Tabs.defaultProps = {
  tabs: []
};
