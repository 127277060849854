import Web3 from 'web3';
import lightwallet from '../../node_modules/eth-lightwallet/dist/lightwallet';
import BigNumber from 'bignumber.js';

import { store } from '@store';

import { setLwAction } from '@features/Auth/actions';
import {
  setPasswordErrorAction,
  setTxStatus,
  setBalance,
  setFixedFee,
  setPercentageFee
} from '@features/Cabinet/actions';

// abi
import { contracts } from './contracts';

// helpers
import { isArraysEqual, uint8ToArray } from '@helpers';
import { statuses } from '@constants';

class LightWalletService {
  constructor() {
    if (localStorage.getItem('keyStore')) {
      this.keystore = lightwallet.keystore.deserialize(localStorage.getItem('keyStore'));
      this.address = this.keystore.getAddresses()[0];
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }

    this.web3 = new Web3(`${process.env.REACT_APP_RPC_URL}` || Web3.givenProvider);
    this.contract = new this.web3.eth.Contract(contracts.abi, contracts.address);
    this.makeTx = this.makeTx.bind(this);
  }

  checkLogIn = () => {
    return this.isLoggedIn;
  };

  generateSeed = () => {
    return lightwallet.keystore.generateRandomSeed();
  };

  getSeed = () => {
    return this.seedPhrase;
  };

  createWallet = password => {
    const seedPhrase = this.generateSeed();
    this.seedPhrase = seedPhrase;

    lightwallet.keystore.createVault(
      {
        password,
        seedPhrase,
        hdPathString: "m/44'/60'/0'/0"
      },
      (error, ks) => {
        ks.keyFromPassword(password, (err, pwDerivedKey) => {
          if (err) console.log(err);

          this.pwDerivedKey = pwDerivedKey;

          ks.generateNewAddress(pwDerivedKey, 1);
          this.address = ks.getAddresses()[0];

          this.keystore = ks;
          this.isLoggedIn = true;
          localStorage.setItem('keyStore', this.keystore.serialize());
          localStorage.setItem('pwDerivedKey', pwDerivedKey);
        });
      }
    );
  };

  importWallet = (seedPhrase, password) => {
    lightwallet.keystore.createVault(
      {
        password,
        seedPhrase,
        hdPathString: "m/44'/60'/0'/0"
      },
      (error, ks) => {
        ks.keyFromPassword(password, (err, pwDerivedKey) => {
          this.pwDerivedKey = pwDerivedKey;
          localStorage.setItem('pwDerivedKey', pwDerivedKey);

          if (err) {
            console.log(err);
          }

          ks.generateNewAddress(pwDerivedKey, 1);
          this.address = ks.getAddresses()[0];

          this.keystore = ks;
          this.isLoggedIn = true;

          localStorage.setItem('keyStore', this.keystore.serialize());

          store.dispatch(setLwAction(this));
        });
      }
    );
  };

  getPublicKey = () => {
    return this.address;
  };

  clearKeystore = () => {
    this.isLoggedIn = false;
    delete this.address;
    delete this.keystore;
    delete this.seedPhrase;

    store.dispatch(setLwAction(this));
  };

  logInToWallet = () => {
    store.dispatch(setLwAction(this));
  };

  checkCredentials = password => {
    this.keystore.keyFromPassword(password, (err, key) => {
      return this.keystore.isDerivedKeyCorrect(key);
    });
  };

  async makeTx(functionName, args, ethAmount = 0) {
    const number = await this.web3.eth.getTransactionCount(this.address);
    const gasPrice = await this.web3.eth.getGasPrice();
    const amount = new BigNumber(args[1]).times(1e18).toString();
    const walletNumber = args[0];
    const newArgs = [walletNumber, amount];

    const txObject = {
      from: this.address,
      to: contracts.address,
      gasLimit: 110000,
      gasPrice: Number(gasPrice),
      value: this.web3.utils.toWei(String(ethAmount), 'ether'),
      nonce: number
    };

    return lightwallet.txutils.functionTx(contracts.abi, functionName, newArgs, txObject);
  }

  signTx = (rawTx, password, cb) => {
    this.keystore.keyFromPassword(password, (err, key) => {
      const pwDerivedKey = localStorage
        .getItem('pwDerivedKey')
        .split(',')
        .map(item => Number(item));

      if (!isArraysEqual(uint8ToArray(key), pwDerivedKey)) {
        store.dispatch(setPasswordErrorAction('Password is not correct'));
        return;
      }

      const signedTx = lightwallet.signing.signTx(this.keystore, key, rawTx, this.address);

      cb(signedTx);
    });
  };

  sendTx = hexTxString => {
    this.web3.eth
      .sendSignedTransaction(hexTxString)
      .then(receipt => {
        store.dispatch(setTxStatus(statuses.success));
      })
      .catch(err => {
        console.log(err);
      });
  };

  getBalance = () => {
    this.contract.methods
      .balanceOf(this.address)
      .call()
      .then(data => {
        store.dispatch(setBalance(this.web3.utils.fromWei(data)));
      })
      .catch(error => {
        console.log(error);
      });
  };

  fixedFee = () => {
    this.contract.methods
      .fixedFee()
      .call()
      .then(data => {
        store.dispatch(setFixedFee(data));
      })
      .catch(err => {
        console.log(err);
      });
  };

  percentageFee = () => {
    this.contract.methods
      .percentageFee()
      .call()
      .then(data => {
        store.dispatch(setPercentageFee(data / 100));
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export default LightWalletService;
