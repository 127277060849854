import styled from 'styled-components';
import { colors, breakpoints } from '@theme';

export const Pagination = styled.div`
  display: flex;
`;
export const PaginationItem = styled.div`
  width: 33px;
  height: 36px;
  text-align: center;
  border: 1px solid ${colors.lightGray};

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
`;

export const PaginationLink = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${colors.violet};
  background: ${colors.white};
  border: none;
  cursor: pointer;
  outline: none;

  ${({ disabled }) => disabled && `color: ${colors.lightGray}`}
  ${({ active }) => active && `color: ${colors.white}; background: ${colors.violet}`}

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 12px;
  }
`;

export const Icon = styled.div`
  cursor: pointer;

  ${({ disabled }) => disabled && `color: ${colors.lightGray};`}
`;
