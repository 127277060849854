import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode-react';
import { useSelector, useDispatch } from 'react-redux';

import { ContentWrapper } from '@common';
import { Button } from '@formComponents';
import { Transactions, SendTxModal } from './components';
import { addPublicAddress } from '@features/Cabinet/actions';
import * as Styled from './styles';
import * as StyledTypography from '@theme/typography';
import { colors } from '@theme';
import { ReactComponent as Logo } from '@images/logo.svg';
import { ReactComponent as LogOut } from '@images/logOut.svg';
import { modifyAmount, hideString } from '@helpers';
import { useCopiedText } from '@hooks';
import { getLw } from '@features/Auth/selectors';
import { getBalance, getPublicAddress } from '@features/Cabinet/selectors';

export const Cabinet = () => {
  const dispatch = useDispatch();
  const lw = useSelector(getLw);
  const walletBalance = useSelector(getBalance);
  const publicAddress = useSelector(getPublicAddress);

  const [publicKey, setPublicKey] = useState('');
  const [balance, setBalance] = useState('');
  const [isModalShown, setIsModalShown] = useState(false);

  const { ref, isCopied, copyToClipboard } = useCopiedText();

  useEffect(() => {
    if (lw.isLoggedIn && lw.address) {
      const publicKey = lw.address;
      dispatch(addPublicAddress(publicKey));
      lw.getBalance();

      setPublicKey(publicKey);
      setBalance(walletBalance);
    }
  }, [walletBalance, lw]);

  const logOut = () => {
    localStorage.clear();
    lw.clearKeystore();
  };

  const toggleModal = () => {
    setIsModalShown(!isModalShown);
  };

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Flex>
          <Logo />
          <Styled.Link href={`${process.env.REACT_APP_CEX_LINK}`} target="_blank" data-test="link-to-cex">
            CEX
          </Styled.Link>
        </Styled.Flex>
        <Styled.Icon>
          <LogOut data-test="logout-icon" onClick={logOut} />
        </Styled.Icon>
      </Styled.Header>

      <ContentWrapper>
        <Styled.Root>
          <Styled.Left>
            <Styled.FlexContainer>
              <StyledTypography.H2>EEX Wallet Info</StyledTypography.H2>
            </Styled.FlexContainer>
            <Styled.Container>
              <Styled.QRWrapper>
                <QRCode value={publicAddress} />
                <Styled.Small noCursor>Your QR Code</Styled.Small>
              </Styled.QRWrapper>

              <Styled.Bottom>
                <Styled.CopyArea>
                  {hideString(publicKey, 10, 30)}
                  <Styled.Hidden data-test="public-address" value={publicKey} ref={ref} readOnly />
                </Styled.CopyArea>

                <Styled.Small data-test="copy-btn" onClick={copyToClipboard} active={isCopied}>
                  {isCopied ? <span>Copied!</span> : <span>Copy to clipboard</span>}
                </Styled.Small>
              </Styled.Bottom>
            </Styled.Container>
          </Styled.Left>
          <Styled.Right>
            <Styled.FlexContainer>
              <StyledTypography.H2>Transactions</StyledTypography.H2>
              <Styled.FlexContainer>
                <Styled.Flex>
                  <Styled.Text>Balance: </Styled.Text>
                  <Styled.Text bold color={colors.violet} data-test="balance">
                    {modifyAmount(balance)}
                  </Styled.Text>
                  <Styled.Text bold> EEX</Styled.Text>
                </Styled.Flex>
                <Button testId="send" customStyles={Styled.buttons.send} onClick={toggleModal}>
                  Send
                </Button>
              </Styled.FlexContainer>
            </Styled.FlexContainer>

            <Transactions />
          </Styled.Right>
        </Styled.Root>
      </ContentWrapper>

      {isModalShown && <SendTxModal closeModal={toggleModal} />}
    </Styled.Wrapper>
  );
};
