import styled from 'styled-components';
import { breakpoints, colors } from '@theme';

export const Root = styled.div`
    width: 100%;
    height: min-content;
    overflow: auto;
  }`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  font-size: 16px;
  ${({ color }) => color && `color: ${color};`}

  @media (max-width: ${breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const Tab = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 10px 0;
  font-size: 16px;
  color: ${colors.almostBlack};
  background: ${colors.white};
  border: none;
  cursor: pointer;
  border-radius: 3px 3px 0 0;

  &:focus {
    outline: none;
  }

  ${({ active }) =>
    active
      ? `
    border-color: ${colors.lightGray}; 
    border-right: 1px solid ${colors.lightGray};
    border-left: 1px solid ${colors.lightGray};
    border-top: 1px solid ${colors.lightGray};
    color: ${colors.violet};`
      : `border-bottom: 1px solid ${colors.lightGray}`}

  ${({ disabled }) => disabled && ` color: ${colors.lightGray};`}
`;
