import React from 'react';
import { useForm } from 'use-form-validation-hook';
import PropTypes from 'prop-types';

import { Form, Input, Button } from '@formComponents';
import { validation } from './validation';

export const CreateWalletStep1 = ({ onSubmit }) => {
  const initialState = {
    password: '',
    confirm: ''
  };

  const { values, errors, handleChange, handleSubmit } = useForm(initialState, validation);

  return (
    <Form onSubmit={handleSubmit(values => onSubmit(values.password))}>
      <Input
        label="New password (min 8 chars)"
        value={values.password}
        type="password"
        onChange={handleChange}
        name="password"
        placeholder="Create password"
        error={errors.password}
        errorTestId="create-wallet-password-error"
        testId="create-wallet-new-password"
        withEye
      />

      <Input
        label="Confirm password"
        value={values.confirm}
        onChange={handleChange}
        type="password"
        name="confirm"
        placeholder="Enter password once again"
        error={errors.confirm}
        testId="create-wallet-confirm-password"
        errorTestId="create-wallet-confirm-error"
        withEye
      />

      <Button testId="create-wallet-step1-next" customStyles={`margin-top: 10px;`}>
        Next
      </Button>
    </Form>
  );
};

CreateWalletStep1.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
