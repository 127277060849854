import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'use-form-validation-hook';

import { TextArea } from './styles';
import { Label, Error } from '@theme/form';
import { Form, Input, Button } from '@formComponents';
import { validation } from './validation';
import { getLw } from '@features/Auth/selectors';

export const ImportWallet = () => {
  const lw = useSelector(getLw);

  const initialState = {
    password: '',
    confirm: '',
    seedPhrase: ''
  };

  const { values, errors, handleChange, handleSubmit } = useForm(initialState, validation);

  const signIn = () => {
    lw.importWallet(values.seedPhrase.trim(), values.password);
  };

  return (
    <Form onSubmit={handleSubmit(signIn)}>
      <Label>
        Seed phrase
        <TextArea
          onChange={handleChange}
          name="seedPhrase"
          placeholder="Seed phrase"
          value={values.seedPhrase}
          rows={5}
          error={errors.seedPhrase}
          data-test="import-wallet-seedphrase"
        ></TextArea>
      </Label>

      {errors.seedPhrase && <Error data-test="import-wallet-seedphrase-error">{errors.seedPhrase}</Error>}

      <Input
        label="New password (min 8 chars)"
        name="password"
        type="password"
        placeholder="Create password"
        onChange={handleChange}
        value={values.password}
        error={errors.password}
        testId="import-wallet-password"
        errorTestId="import-wallet-password-error"
        withEye
      />

      <Input
        label="Confirm password"
        name="confirm"
        type="password"
        placeholder="Enter password once again"
        onChange={handleChange}
        value={values.confirm}
        error={errors.confirm}
        testId="import-wallet-confirm-password"
        errorTestId="import-wallet-confirm-error"
        withEye
      />

      <Button testId="import-wallet-login-btn">Log in to wallet</Button>
    </Form>
  );
};
