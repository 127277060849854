import { useState, useEffect, useCallback, useRef } from 'react';

export const usePagination = (offset, limit, callback) => {
  const [stateOffset, setStateOffset] = useState(offset);
  const [stateLimit, setStateLimit] = useState(limit);

  const handleChange = useCallback(({ offset, limit }) => {
    setStateOffset(offset);
    setStateLimit(limit);
  }, []);

  useEffect(() => {
    callback(stateOffset, stateLimit);
  }, [stateOffset, stateLimit, callback]);

  return {
    offset: stateOffset,
    limit: stateLimit,
    handleChange
  };
};

export const useCopiedText = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const ref = useRef(null);

  const copyToClipboard = (event, text) => {
    // sometimes we can't use input element in html because of design (hover on row)
    if (text) {
      navigator.clipboard.writeText(text);
    } else {
      ref.current.select();
      document.execCommand('copy');
    }

    setIsCopied(true);
    const timeoutId = setTimeout(() => {
      setIsCopied(false);
    }, 2000);
    setTimeoutId(timeoutId);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  return { ref, copyToClipboard, isCopied };
};
