import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Input, Button } from '@formComponents';
import { H2, Info, Text, Buttons, buttons, confirmBtn } from './styles';
import { Flex } from '@theme';
import { modifyAmount } from '@helpers';
import { setTxStatus, setPasswordErrorAction } from '@features/Cabinet/actions';
import { getLw } from '@features/Auth/selectors';
import { getAddressToMakeTx, getPasswordError, getPasswordAttempt, getAmount } from '@features/Cabinet/selectors';
import { ReactComponent as Cross } from '@images/cross.svg';
import { statuses } from '@constants';

export const SendTxStep2 = ({ onClose }) => {
  const dispatch = useDispatch();
  const lw = useSelector(getLw);
  const addressToMakeTx = useSelector(getAddressToMakeTx);
  const tokensAmount = useSelector(getAmount);
  const reduxPasswordError = useSelector(getPasswordError);
  const passwordAttempt = useSelector(getPasswordAttempt);

  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [password, setPassword] = useState('');

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value);
  };

  const sendTransaction = () => {
    setPasswordError('');
    dispatch(setPasswordErrorAction(null));

    dispatch(setTxStatus(statuses.loading));
    setIsLoading(true);
    const tx = lw.makeTx('transfer', [addressToMakeTx, tokensAmount]);

    tx.then((txx, error) => {
      if (error) {
        console.log(error);
      }

      lw.signTx(txx, password, signed => {
        lw.sendTx(`0x${signed}`);
      });
    }).catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    if (!reduxPasswordError) {
      setPassword('');
    } else {
      setIsLoading(false);
      dispatch(setTxStatus(statuses.error));
      setPasswordError(reduxPasswordError);
    }
  }, [reduxPasswordError, passwordAttempt]);

  return (
    <>
      <Flex border>
        <H2>Transaction confirmation</H2>
        <Cross onClick={isLoading ? () => {} : onClose} disabled={isLoading} data-test="step2-cross-btn" />
      </Flex>
      <Flex direction="column" padding="40">
        <Flex direction="column" align="flex-start" padding="0">
          <Text padding="0" size="16px">
            You will send
          </Text>
          <Info margin="0" bold size="38px" data-test="step2-amount">
            {modifyAmount(tokensAmount)} EEX Coins
          </Info>
          <Text size="16px" padding="0">
            to
          </Text>
          <Text data-test="step2-recipient" padding="0" bold size="16px" width="100%">
            {addressToMakeTx}
          </Text>
        </Flex>

        <Input
          type="password"
          value={password}
          name="password"
          onChange={handlePasswordChange}
          testId="password"
          error={passwordError}
          errorTestId="password-error"
        />
      </Flex>
      <Buttons>
        <Button
          customStyles={confirmBtn(isLoading)}
          onClick={sendTransaction}
          isLoading={isLoading}
          testId="step2-confirm"
        >
          Confirm
        </Button>
        <Button customStyles={buttons.cancel} onClick={onClose} isDisabled={isLoading} testId="step2-cancel">
          Cancel
        </Button>
      </Buttons>
    </>
  );
};

SendTxStep2.propTypes = {
  onClose: PropTypes.func.isRequired
};
