import styled from 'styled-components';
import { breakpoints, colors } from '@theme';

export const Root = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.almostBlack};
  justify-content: center;
  padding: 80px 0;
  background-image: linear-gradient(
    to left,
    ${colors.theDarkestViolet},
    ${colors.darkViolet},
    ${colors.indigo},
    ${colors.pinkViolet}
  );
`;

export const LeftTop = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  width: 33%;
`;

export const RightBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 33%;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 40px;
`;

export const FormWrapper = styled.div`
  background: #fff;
  width: 32%;
  display: flex;
  flex-direction: column;
  padding: 36px;
  border-radius: 5px;
  z-index: 1;

  @media (max-width: ${breakpoints.lg}px) {
    width: 44%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 70%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 94%;
    padding: 15px;
  }
`;
