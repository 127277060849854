import React, { memo } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

export const ContentWrapper = memo(({ children }) => <Styled.ContentWrapper>{children}</Styled.ContentWrapper>);

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
