import React from 'react';
import { colors } from '@theme';
import { ReactComponent as Fish } from '@images/fish.svg';

import { Wrapper, LoadingWrapper, LoadingChild, FishWrapper } from './styles';

export const Loader = ({ color = colors.mediumViolet, withFish = true }) => (
  <Wrapper>
    {withFish && (
      <>
        <FishWrapper>
          <Fish color={color} />
        </FishWrapper>

        <LoadingWrapper>
          <LoadingChild color={color} />
          <LoadingChild color={color} />
          <LoadingChild color={color} />
          <LoadingChild color={color} />
        </LoadingWrapper>
      </>
    )}
  </Wrapper>
);
