import React from 'react';
import PropTypes from 'prop-types';

import { Message, Small, Hidden } from './styles';
import { Form, colors } from '@theme';
import { Button } from '@formComponents';
import { Span } from '@theme/typography';
import { useCopiedText } from '@hooks';

export const CreateWalletStep3 = ({ seedPhrase, onSubmit }) => {
  const { ref, isCopied, copyToClipboard } = useCopiedText();

  return (
    <Form>
      <p>Seed phrase</p>
      <Message>
        <Hidden ref={ref} value={seedPhrase} onClick={copyToClipboard} readOnly />
        {seedPhrase}
      </Message>
      <Small data-test="create-wallet-copy-btn" onClick={copyToClipboard}>
        {isCopied ? <Span color={colors.green}>Copied!</Span> : <span>Copy to clipboard</span>}
      </Small>
      <Button testId="create-wallet-finish-btn" onClick={onSubmit}>
        Finish registration
      </Button>
    </Form>
  );
};

CreateWalletStep3.propTypes = {
  seedPhrase: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};
