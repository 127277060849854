import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const Wrapper = styled.section`
  position: fixed;
  z-index: 1;
  overflow: auto;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background: rgba(18, 7, 44, 0.9);

  @media (max-width: ${breakpoints.sm}px) {
    align-items: flex-start;
  }
`;

export const ModalWindow = styled.div`
  width: 44%;
  background: #fff;
  border-radius: 4px;
  color: #2c2a32;
  font-family: 'Fira Sans', sans-serif;

  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${breakpoints.lg}px) {
    width: 60%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 92%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    min-height: 100%;
    border-radius: 0px;
  }
`;
