import styled from 'styled-components';

export const Info = styled.div`
  padding: 10px 0 20px;

  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const Emoji = styled.span`
  font-size: 30px;
`;
