import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export const H2 = styled.h2`
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
  margin: 10px 0;

  @media (max-width: ${breakpoints.lg}px) {
    font-size: 24px;
  }

  @media (max-width: ${breakpoints.md}px) {
    font-size: 20px;
  }
`;

export const Span = styled.span`
  font-size: 16px;
  ${({ color }) => color && `color: ${color}`}

  @media (max-width: ${breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const AnchorLink = styled.a`
  text-decoration: none;
  color: #fff;
  ${({ color }) => color && `color: ${color};`}
  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;
