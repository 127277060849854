import { passwordRules } from '@features/Auth/validation';
const bip39 = require('bip39');

export const validation = [
  {
    rules: passwordRules,
    name: 'password'
  },
  {
    matchWithField: {
      field: 'password',
      message: 'Password and Confirm password fields should be equal.'
    },
    name: 'confirm'
  },
  {
    rules: [
      {
        regex: value => !!value.length,
        message: 'This field is required.'
      },
      {
        regex: value => value.split(' ').length >= 12,
        message: 'Seed phrase should consist of 12 words.'
      },
      {
        regex: value => bip39.validateMnemonic(value.trim()),
        message: 'Seed phrase is not correct.'
      }
    ],
    name: 'seedPhrase'
  }
];
