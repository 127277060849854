import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QrReader from 'react-qr-scanner';
import BigNumber from 'bignumber.js';
import PropTypes from 'prop-types';

import { modifyAmount, convertExponentialToDecimal, getFractionPart } from '@helpers';
import { countIfPaidBy } from './helpers';
import * as Styled from './styles';
import { Flex } from '@theme';

import { errors } from '@constants';
import { paidBy } from './constants';
import { colors } from '@theme/colors';
import { Input, Button } from '@formComponents';
import { setAddressAction, setAmountAction, setTxStep } from '@features/Cabinet/actions';
import { getLw } from '@features/Auth/selectors';
import { getFixedFee, getPercentageFee, getBalance, getAmount } from '@features/Cabinet/selectors';
import { ReactComponent as Cross } from '@images/cross.svg';

export const SendTxStep1 = ({ onClose }) => {
  const dispatch = useDispatch();
  const lw = useSelector(getLw);
  const fixed = useSelector(getFixedFee);
  const percentage = useSelector(getPercentageFee);
  const balance = useSelector(getBalance);
  const tokensAmount = useSelector(getAmount);

  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [addressError, setAddressError] = useState(null);
  const [fixedFee, setFixedFee] = useState('');
  const [percentageFee, setPercentageFee] = useState('');
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [youWillSend, setYouWillSend] = useState(0);
  const [recipientWillReceive, setRecipientWillReceive] = useState(0);
  const [isChecked, setIsChecked] = useState(true);
  const [amountError, setAmountError] = useState(null);
  const delay = 0;
  const [totalFee, setTotalFee] = useState('');

  useEffect(() => {
    setFixedFee(new BigNumber(fixed).dividedBy(1e18).toString());
    setPercentageFee(new BigNumber(percentage).dividedBy(100).toString());
  }, [fixed, percentage]);

  useEffect(() => {
    setAmount(String(tokensAmount));
  }, [tokensAmount]);

  const clearFees = useCallback(() => {
    setYouWillSend(0);
    setRecipientWillReceive(0);
    setTotalFee(0);
  }, []);

  const setAmounts = useCallback(
    paidBy => {
      const { youWillSend, recipientWillReceive, totalFee } = paidBy;

      if (Number(new BigNumber(balance).minus(new BigNumber(youWillSend))) < 0) {
        setAmountError(errors.insufficient);
        setDisabled(true);
        return;
      }

      setYouWillSend(youWillSend.toString());
      setRecipientWillReceive(recipientWillReceive.toString());
      setTotalFee(totalFee.toString());
      setAmountError('');
      setDisabled(false);
    },
    [balance]
  );

  const handleAmountChange = useMemo(
    () => ({ target: { value } }) => {
      if (Number.isNaN(Number(value)) || value === ' ') return;

      const fractionPart = String(getFractionPart(value));
      if (fractionPart.length > 8) return;

      if (isChecked) {
        const paidBySender = countIfPaidBy(paidBy.sender, value, fixedFee, percentageFee);

        if (paidBySender) {
          setAmounts(paidBySender);
        } else {
          clearFees();
        }
      } else {
        const paidByRecipient = countIfPaidBy(paidBy.recipient, value, fixedFee, percentageFee);

        if (paidByRecipient) {
          setAmounts(paidByRecipient);

          if (Number(new BigNumber(value).minus(new BigNumber(totalFee))) < 0) {
            setRecipientWillReceive('0');
            setDisabled(true);
          } else {
            setYouWillSend(youWillSend.toString());
            setRecipientWillReceive(recipientWillReceive.toString());
            setTotalFee(totalFee.toString());
            setAmountError('');
            setDisabled(false);
          }
        } else {
          clearFees();
        }
      }

      setAmount(value);
    },
    [
      isChecked,
      setTotalFee,
      setAmountError,
      setDisabled,
      clearFees,
      setYouWillSend,
      setRecipientWillReceive,
      fixedFee,
      percentageFee,
      youWillSend,
      recipientWillReceive,
      setAmounts,
      totalFee
    ]
  );

  const handleAddressChange = ({ target: { value } }) => {
    dispatch(setAddressAction(value));
    setAddress(value);
  };

  const toggleCamera = () => {
    setIsCameraOpen(!isCameraOpen);
  };

  const handleError = error => {
    throw new Error(error);
  };

  const handleScan = data => {
    if (data) {
      setIsCameraOpen(false);
      setAddress(data);
      dispatch(setAddressAction(data));
    }
  };

  const makeTransaction = () => {
    const error = validateFields();
    const { addressError, amountError } = error;

    if (!error.addressError && !amountError) {
      dispatch(setTxStep(2));
      dispatch(setAmountAction(youWillSend));
    }
    setAddressError(addressError);
    setAmountError(amountError);
  };

  const validateFields = () => {
    const isValidAddress = lw.web3.utils.isAddress(address);
    const addressError = !isValidAddress ? errors.invalidAddress : null;
    let amountError = null;

    const bal = new BigNumber(balance);
    const am = new BigNumber(amount);
    const sub = bal.minus(am).toFixed();

    if (Number(sub) < 0) {
      amountError = errors.insufficient;
    } else if (isNaN(sub)) {
      amountError = errors.required;
    }

    return {
      addressError,
      amountError
    };
  };

  const handleToggle = useMemo(
    () => () => {
      if (!isChecked) {
        const paidBySender = countIfPaidBy(paidBy.sender, amount, fixedFee, percentageFee);

        if (paidBySender) {
          const { youWillSend, recipientWillReceive, totalFee } = paidBySender;

          if (Number(new BigNumber(balance).minus(new BigNumber(youWillSend))) < 0) {
            const amount = modifyAmount(new BigNumber(balance).minus(totalFee).toString());
            const paidBySender = countIfPaidBy(paidBy.sender, amount, fixedFee, percentageFee);
            const { youWillSend, recipientWillReceive } = paidBySender;

            setDisabled(false);
            setAmount(amount);
            setYouWillSend(youWillSend.toString());
            setRecipientWillReceive(recipientWillReceive.toString());
          } else {
            setYouWillSend(youWillSend.toString());
            setRecipientWillReceive(recipientWillReceive.toString());
            setTotalFee(totalFee.toString());
            setAmountError('');
            setDisabled(false);
          }
        } else {
          clearFees();
        }
      } else {
        if (amount) {
          const paidByRecipient = countIfPaidBy(paidBy.recipient, amount, fixedFee, percentageFee);

          if (paidByRecipient) {
            const { youWillSend, recipientWillReceive, totalFee } = paidByRecipient;

            if (Number(new BigNumber(amount).minus(new BigNumber(totalFee))) < 0) {
              setRecipientWillReceive('0');
              setYouWillSend('0');
              setDisabled(true);
            } else {
              setYouWillSend(youWillSend.toString());
              setRecipientWillReceive(recipientWillReceive.toString());
              setTotalFee(totalFee.toString());
              setAmountError('');
              setDisabled(false);
            }
          } else {
            clearFees();
          }
        }
      }

      setIsChecked(!isChecked);
    },
    [
      isChecked,
      setRecipientWillReceive,
      setYouWillSend,
      setDisabled,
      setTotalFee,
      clearFees,
      setAmountError,
      amount,
      balance,
      fixedFee,
      percentageFee
    ]
  );

  return (
    <>
      <Flex border>
        <Styled.H2>Send</Styled.H2>
        <Cross onClick={onClose} data-test="cross-btn-step1" />
      </Flex>

      <Styled.Label htmlFor="address" marginBottom="-10px">
        Add recipient
      </Styled.Label>
      <Flex border padding="0 20px 20px 20px">
        <Styled.Wrapper>
          <Input
            type="text"
            placeholder={'Public address (0x)'}
            value={address}
            onChange={handleAddressChange}
            name="address"
            error={addressError}
            testId="recipient-input"
            errorTestId="address-error"
          />
          <Button onClick={toggleCamera} customStyles={Styled.buttons.scan} hiddenForMobileDevices testId="scan-btn">
            Scan QR Code
          </Button>
        </Styled.Wrapper>

        {isCameraOpen && (
          <Styled.CameraWrapper data-test="camera-wrapper">
            <QrReader
              delay={delay}
              style={Styled.cameraStyles}
              onError={handleError}
              onScan={handleScan}
              data-test="qr-reader"
            />
            <Cross color={colors.violet} onClick={toggleCamera} data-test="camera-cross-btn" />
          </Styled.CameraWrapper>
        )}
      </Flex>

      <Flex padding="0 20px">
        <Input
          label="Balance"
          type="text"
          name="balance"
          value={`${modifyAmount(balance)} EEX`}
          background="gray"
          margin="10px 0 0 0"
          testId="balance-input"
          isDisabled
        />
      </Flex>

      <Flex border padding="0 20px">
        <Styled.Amounts>
          <Input
            label="Amount"
            type="text"
            placeholder="0 EEX"
            value={amount}
            onChange={handleAmountChange}
            error={amountError}
            errorTestId="amount-error"
            name="amount"
            id="amount"
            testId="amount-input"
          />
          <Styled.CheckBoxWrapper>
            <Styled.CheckBox
              onChange={handleToggle}
              checked={isChecked}
              type="checkbox"
              id="checkbox"
              data-test="step1-toggler"
            />
            <Styled.CheckBoxLabel
              htmlFor="checkbox"
              style={isChecked ? Styled.checkboxStyles.checked : Styled.checkboxStyles.unchecked}
            />
            <Styled.Message size="16px" data-test="toggle-info">
              {isChecked ? 'Fee paid by Sender' : 'Fee paid by Recipient'}
            </Styled.Message>
          </Styled.CheckBoxWrapper>
        </Styled.Amounts>
        <Styled.Text padding="10px 0">
          Fee: {fixedFee} + {percentage}% (
          <Styled.Bold data-test="total-fee">{totalFee ? modifyAmount(totalFee) : '0'}</Styled.Bold> EEX)
        </Styled.Text>
      </Flex>

      <Flex background={colors.theLightestGray} border direction="column">
        <Flex padding="0" justify="space-between">
          <p>You will send</p>
          <p>Recipient will receive</p>
        </Flex>
        <Flex padding="0">
          <Styled.Info data-test="you-will-send" bold size="38px">
            {modifyAmount(String(convertExponentialToDecimal(youWillSend)))}
          </Styled.Info>
          <Styled.Info data-test="recipient-will-receive" bold size="38px">
            {modifyAmount(String(convertExponentialToDecimal(recipientWillReceive)))}
          </Styled.Info>
        </Flex>
      </Flex>
      <Styled.Buttons>
        <Button
          onClick={makeTransaction}
          isDisabled={disabled}
          customStyles={Styled.nextBtn(disabled)}
          testId="step1-next-btn"
        >
          Next
        </Button>
        <Button customStyles={Styled.buttons.cancel} onClick={onClose} testId="step1-cancel-btn">
          Cancel
        </Button>
      </Styled.Buttons>
    </>
  );
};

SendTxStep1.propTypes = {
  onClose: PropTypes.func.isRequired
};
