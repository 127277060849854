export const passwordRules = [
  {
    regex: value => !!value.length,
    message: 'This field is required.'
  },
  {
    regex: value => value.length >= 8,
    message: 'Minimum length is 8 symbols.'
  },
  {
    regex: value => !value.includes(' '),
    message: 'Spaces are not allowed.'
  }
];
