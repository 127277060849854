import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ImportWallet, CreateWallet } from './components';
import { Tabs } from '@features/Common';
import { getStep } from '@features/Auth/selectors';
import * as Styled from './styles';
import * as StyledTypography from '@theme/typography';
import { ReactComponent as Logo } from '@images/logo.svg';
import { ReactComponent as LeftTopBg } from '@images/leftTopBg.svg';
import { ReactComponent as RightBottomBg } from '@images/rightBottomBg.svg';

export const Auth = () => {
  const createWalletStep = useSelector(getStep);

  const authTabs = useMemo(
    () => [
      { label: 'Create Wallet', Component: CreateWallet, isDisabled: createWalletStep > 2, testId: 'create-wallet' },
      { label: 'Import Wallet', Component: ImportWallet, isDisabled: createWalletStep > 2, testId: 'import-wallet' }
    ],
    [createWalletStep]
  );

  return (
    <Styled.Root>
      <Styled.LeftTop>
        <LeftTopBg />
      </Styled.LeftTop>
      <Styled.RightBottom>
        <RightBottomBg />
      </Styled.RightBottom>

      <Styled.LogoWrapper>
        <Logo />
      </Styled.LogoWrapper>

      <Styled.FormWrapper>
        <StyledTypography.H2>Welcome to EEX Wallet!</StyledTypography.H2>

        <Tabs tabs={authTabs}>
          {authTabs.map(({ Component, label }) => (
            <Component key={label} />
          ))}
        </Tabs>
      </Styled.FormWrapper>
    </Styled.Root>
  );
};
