import styled from 'styled-components';
import { breakpoints } from '@theme';

export const H2 = styled.h2`
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin: 0;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 22px;
  }
`;

export const Info = styled.span`
  margin-left: 5px;
  color: #441eda;

  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ size }) => size && `font-size: ${size};`}
  ${({ margin }) => margin && `margin: ${margin};`}

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 24px;
    margin-left: 0;
  }
`;

export const Text = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding: 0;

  ${({ size }) => size && `font-size: ${size};`}
  ${({ bold }) => bold && `font-weight: bold;`}
  ${({ padding }) => padding && `padding: ${padding};`}

  @media (max-width: ${breakpoints.sm}px) {
    word-wrap: break-word;
    ${({ width }) => width && `width: ${width};`}
  }
`;

export const buttons = {
  ok: `width: 150px;`
};
