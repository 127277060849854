import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Wrapper, ModalWindow } from './styles';
import { SendTxStep1, SendTxStep2, SendTxStep3 } from './components';
import { setAddressAction, setAmountAction, setTxStatus } from '@features/Cabinet/actions';
import { statuses } from '@constants';
import { setTxStep } from '@features/Cabinet/actions';
import { getLw } from '@features/Auth/selectors';
import { getTxStatus, getTxStep } from '@features/Cabinet/selectors';

const steps = [
  { id: 1, Component: SendTxStep1 },
  { id: 2, Component: SendTxStep2 },
  { id: 3, Component: SendTxStep3 }
];

export const SendTxModal = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const lw = useSelector(getLw);
  const txStatus = useSelector(getTxStatus);
  const step = useSelector(getTxStep);

  useEffect(() => {
    lw.fixedFee();
    lw.percentageFee();
  }, [lw]);

  useEffect(() => {
    txStatus === statuses.loading && setIsLoading(true);
    txStatus === statuses.success && dispatch(setTxStep(3)) && setIsLoading(false);
  }, [txStatus]);

  const close = event => {
    event.stopPropagation();
    dispatch(setAmountAction(''));
    dispatch(setAddressAction(''));
    dispatch(setTxStatus(statuses.none));
    dispatch(setTxStep(1));

    closeModal(event);
  };

  return (
    <Wrapper onClick={isLoading ? () => {} : close} data-test="modal-background">
      <ModalWindow onClick={event => event.stopPropagation()}>
        {steps.map(({ id, Component }) => id === step && <Component key={id} onClose={close} />)}
      </ModalWindow>
    </Wrapper>
  );
};

SendTxModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};
