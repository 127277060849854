import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '@common';
import { StyledButton } from './style';

export const Button = ({
  children,
  onClick,
  type,
  isDisabled,
  testId,
  isLoading,
  loaderColor,
  hiddenForMobileDevices,
  customStyles
}) => {
  return (
    <StyledButton
      data-test={testId}
      type={type}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      customStyles={customStyles}
      hiddenForMobileDevices={hiddenForMobileDevices}
    >
      {isLoading ? <Loader color={loaderColor} /> : children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  testId: PropTypes.string,
  hiddenForMobileDevices: PropTypes.bool,
  customStyles: PropTypes.string
};

Button.defaultProps = {
  isDisabled: false
};
