import styled from 'styled-components';
import { breakpoints, colors } from '@theme';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e3e8;
  border-radius: 4px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e3e8;
  color: ${colors.gray};
  padding: 10px;

  &:nth-child(5) {
    border: none;
  }

  &:hover {
    background: ${colors.theLightestGray};
  }

  @media (max-width: ${breakpoints.sm}px) {
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 5px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.span`
  color: ${colors.almostBlack};
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ color }) => color && `color: ${color};`};
  ${({ weight }) => weight && `font-weight: ${weight};`};
  ${({ size }) => size && `font-size: ${size}px;`};

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 12px;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ padding }) => padding && `padding: ${padding};`};

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 20px;
  }
`;

export const Amount = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
`;

export const TxWrapper = styled.div`
  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50px 330px 20px;
  align-items: center;

  @media (max-width: ${breakpoints.sm}px) {
    grid-template-columns: 2.5fr 15fr 1fr;
  }
`;

export const Info = styled.p`
  color: #b2afbd;
  margin: 10px;
`;

export const Error = styled.p`
  font-weight: 500;
  color: #ba2713;
  margin: 10px;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const Icon = styled.div`
  cursor: pointer;
  margin-right: 10px;

  @media (max-width: ${breakpoints.sm}px) {
    right: 5px;
  }
`;

export const CopyIcon = styled.div`
  cursor: pointer;
  margin-bottom: 3px;

  &:hover {
    & > * {
      color: ${colors.violet};
    }
  }
`;

export const Link = styled.a`
  color: ${colors.almostBlack};
  text-decoration: underline;
`;
