import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGa from 'react-ga';

import { Auth, Cabinet } from '@features';
import LightWalletService from '@lwService';
import { setLwAction } from '@features/Auth/actions';
import { getLw } from '@features/Auth/selectors';
import GlobalStyle from '@theme/global-style';

export const App = () => {
  const dispatch = useDispatch();
  const lw = useSelector(getLw);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const lw = new LightWalletService();
    dispatch(setLwAction(lw));

    // Google analytics
    ReactGa.initialize('UA-154900187-2');
    ReactGa.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    lw && setIsLoggedIn(lw.isLoggedIn);
  }, [lw]);

  return (
    <>
      <GlobalStyle />
      {isLoggedIn ? <Cabinet /> : <Auth />}
    </>
  );
};
