import { handleActions } from 'redux-actions';
import {
  addPublicAddress,
  setAmountAction,
  setAddressAction,
  setTxStatus,
  setTxStep,
  setPasswordErrorAction,
  setBalance,
  setFixedFee,
  setPercentageFee,
  setTransactionsData,
  setTransactionsError,
  setTransactionsStatus,
  setAllTransactionsData,
  setAllTransactionsError,
  setAllTransactionsStatus
} from '@features/Cabinet/actions';
import { statuses } from '@constants';

const initialState = {
  publicAddress: '',
  balance: '',
  transactions: [],
  allTransactions: [],
  fixedFee: '',
  percentageFee: '',
  passwordError: null,
  amount: '',
  addressToMakeTx: '',
  getTransactionsError: null,
  getAllTransactionsError: null,
  passwordAttempt: 0,
  txStatus: statuses.none,
  txStep: 1,
  transactionsStatus: statuses.none,
  allTransactionsStatus: statuses.none
};

export const cabinet = handleActions(
  {
    [addPublicAddress]: (state, { payload: { publicAddress } }) => ({
      ...state,
      publicAddress
    }),
    [setBalance]: (state, { payload: { balance } }) => ({
      ...state,
      balance
    }),
    [setFixedFee]: (state, { payload: { fixedFee } }) => ({
      ...state,
      fixedFee
    }),
    [setPercentageFee]: (state, { payload: { percentageFee } }) => ({
      ...state,
      percentageFee
    }),
    [setAmountAction]: (state, { payload: { amount } }) => ({
      ...state,
      amount
    }),
    [setAddressAction]: (state, { payload: { addressToMakeTx } }) => ({
      ...state,
      addressToMakeTx
    }),
    [setTransactionsData]: (state, { payload: { data } }) => ({
      ...state,
      transactions: data,
      getTransactionsError: null
    }),
    [setTransactionsError]: (state, { payload: { error } }) => ({
      ...state,
      getTransactionsError: error
    }),
    [setTransactionsStatus]: (state, { payload: { status } }) => ({
      ...state,
      transactionsStatus: status
    }),
    [setAllTransactionsData]: (state, { payload: { data } }) => ({
      ...state,
      allTransactions: data,
      getAllTransactionsError: null
    }),
    [setAllTransactionsError]: (state, { payload: { error } }) => ({
      ...state,
      getAllTransactionsError: error
    }),
    [setAllTransactionsStatus]: (state, { payload: { status } }) => ({
      ...state,
      allTransactionsStatus: status
    }),
    [setPasswordErrorAction]: (state, { payload: { passwordError } }) => ({
      ...state,
      passwordError,
      passwordAttempt: state.passwordAttempt + 1
    }),
    [setTxStatus]: (state, { payload: { txStatus } }) => ({
      ...state,
      txStatus
    }),
    [setTxStep]: (state, { payload: { txStep } }) => ({
      ...state,
      txStep
    })
  },
  initialState
);
