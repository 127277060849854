import { createAction } from 'redux-actions';

export const addPublicAddress = createAction('cabinet/add/address', publicAddress => ({ publicAddress }));
export const setAmountAction = createAction('cabinet/set/amount', amount => ({ amount }));
export const setAddressAction = createAction('cabinet/set/address', addressToMakeTx => ({ addressToMakeTx }));

export const setTxStatus = createAction('cabinet/set/txStatus', txStatus => ({ txStatus }));
export const setTxStep = createAction('cabinet/set/txStep', txStep => ({ txStep }));
export const setPasswordErrorAction = createAction('cabinet/set/passwordError', passwordError => ({ passwordError }));
export const setBalance = createAction('cabinet/set/balance', balance => ({ balance }));
export const setFixedFee = createAction('cabinet/set/fixedFee', fixedFee => ({ fixedFee }));
export const setPercentageFee = createAction('cabinet/set/percentageFees', percentageFee => ({ percentageFee }));
export const getTransactionsAsync = createAction('cabinet/get/transactions', props => props);
export const getAllTransactionsAsync = createAction('cabinet/get/allTransactions', props => props);
export const setTransactionsStatus = createAction('cabinet/get/transactions/status', status => ({ status }));
export const setTransactionsData = createAction('cabinet/get/transactions/data', data => ({ data }));
export const setTransactionsError = createAction('cabinet/get/transactions/error', error => ({ error }));
export const setAllTransactionsStatus = createAction('cabinet/get/allTransactions/status', status => ({ status }));
export const setAllTransactionsData = createAction('cabinet/get/allTransactions/data', data => ({ data }));
export const setAllTransactionsError = createAction('cabinet/get/allTransactions/error', error => ({ error }));
