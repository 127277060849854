import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Copy } from '@images/copy.svg';
import * as Styled from './styles';
import { useCopiedText } from '@hooks';
import { colors } from '@theme';

export const CopyText = ({ children, text, testId }) => {
  const { ref, isCopied, copyToClipboard } = useCopiedText();
  return (
    <>
      <Styled.Text ref={ref}>{children}</Styled.Text>
      <Styled.CopyIcon onClick={() => copyToClipboard(null, text)} data-test={testId}>
        <Copy color={isCopied ? colors.green : colors.gray} />
      </Styled.CopyIcon>
    </>
  );
};

CopyText.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired
};
