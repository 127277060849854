import styled from 'styled-components';
import { colors } from '@theme/colors';

export const TextArea = styled.textarea`
  width: 100%;
  margin: 20px 0 5px;
  font-size: 16px;
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
  resize: none;
  padding: 10px;

  ${({ error }) => error && `border: 2px solid ${colors.red}`}
`;
