import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

export const LoadingWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
`;

const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(14px, 0);
  }
`;

const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const LoadingChild = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  top: 18px;
  border-radius: 50%;

  ${({ color }) => color && `background: ${color};`}

  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 8px;
    animation: ${ldsEllipsis1} 0.6s infinite;
  }

  &:nth-child(2) {
    left: 8px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  &:nth-child(3) {
    left: 22px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  :nth-child(4) {
    left: 36px;
    animation: ${ldsEllipsis3} 0.6s infinite;
  }
`;

const fishMove = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(50px, 0);
  }
}
`;

export const FishWrapper = styled.div`
  width: 64px;
  height: 24px;
  animation: ${fishMove} 1.5s infinite;
`;
