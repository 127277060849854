import { createSelector } from 'reselect';

const auth = state => state.cabinet;

export const getBalance = createSelector(auth, state => state.balance);
export const getPublicAddress = createSelector(auth, state => state.publicAddress);
export const getTxStatus = createSelector(auth, state => state.txStatus);
export const getTxStep = createSelector(auth, state => state.txStep);
export const getFixedFee = createSelector(auth, state => state.fixedFee);
export const getPercentageFee = createSelector(auth, state => state.percentageFee);
export const getAmount = createSelector(auth, state => state.amount);
export const getAddressToMakeTx = createSelector(auth, state => state.addressToMakeTx);
export const getPasswordError = createSelector(auth, state => state.passwordError);
export const getPasswordAttempt = createSelector(auth, state => state.passwordAttempt);
export const getAllTransactions = createSelector(auth, state => state.allTransactions);
export const getTransactions = createSelector(auth, state => state.transactions);
export const getAllTransactionsErrorSelector = createSelector(auth, state => state.getAllTransactionsError);
export const getTransactionsErrorSelector = createSelector(auth, state => state.getTransactionsError);
