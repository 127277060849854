export const colors = {
  violet: '#441EDA',
  lightGray: '#e4e3e8',
  white: '#fff',
  gray: '#A8A5B4',
  red: '#ed2222',
  almostBlack: '#2c2a32',
  theDarkestViolet: '#261066',
  darkViolet: '#3a1269',
  indigo: '#471368',
  pinkViolet: '#59176c',
  green: '#308744',
  lightViolet: '#cdc2ff',
  mediumViolet: '#A987E5',
  theLightestGray: '#f7f7f8'
};
